<template>
  <el-card class="login-form">
    {{ error_message }}
    <el-form v-loading="loading">
      <el-form-item>
        <el-input v-model="form.company_domain" placeholder="企業ドメイン"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.login_id" placeholder="ログインID"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.password" show-password placeholder="パスワード"></el-input>
      </el-form-item>

      <el-button type="primary" @click="onLogin" :disabled="loginButtonDisable">ログイン</el-button>
    </el-form>
  </el-card>
</template>

<script lang="js">
import { ref, reactive, computed } from "vue";
// import axios from "axios";
// import { useStore } from 'vuex'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
//import axios from "axios";
export default {
  name: "Login",
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const error_message = ref("");
    const form = reactive({
      company_domain: route.query.company_domain,
      login_id: "",
      password: "",
    });
    let loading = ref(false)
    const loginButtonDisable = computed(() => {
      return form.login_id.length == 0 || form.password.length == 0;
    });
    // const title = ref<string>("デフォルト");
    const onLogin = () => {
      error_message.value = "";
      loading.value = true;
      store
        .dispatch("login/login", { login_id: form.login_id + '@' + form.company_domain, password: form.password })
        .then(
          response => {
            router.push("/");
            loading.value = false;
          },
          error => {
            loading.value = false;
            error_message.value = "ログインに失敗しました";
          }
        );
      // ログイン成功したときにページに伝えるのがいいかも。
      emit("login", form.login_id, form.password);
    };
    return { onLogin, form, error_message, loginButtonDisable, loading };
  },
};
</script>

<style scoped>
.login-form {
  position: relative;
  top: 30%;
  width: 100%;
}
.el-button {
  width: 100%;
}
</style>

<template>
  <div class="login_box">
    <LoginForm @login="login"></LoginForm>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  setup() {
    // eslint-disable-next-line
    const login = (login_id, password) => {};
    return { login };
  },
};
</script>

<style scoped>
.login_box {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
}
</style>
